import { render, useEffect, useRef } from '@wordpress/element';

import './style.scss';
import Style from './Style';
import TDViewer from './TDViewer';

// 3D Viewer
document.addEventListener('DOMContentLoaded', () => {
	const allTDViewer = document.querySelectorAll('.wp-block-tdvb-td-viewer');
	allTDViewer.forEach(tDViewer => {
		const attributes = JSON.parse(tDViewer.dataset.attributes);

		render(<>
			<Style attributes={attributes} clientId={attributes.cId} />

			<RenderTDViewer attributes={attributes} />
		</>, tDViewer);

		tDViewer?.removeAttribute('data-attributes');
	});
});

const RenderTDViewer = ({ attributes }) => {
	const { isTouchMove, isZoom } = attributes;

	const tdvb3DViewerBlock = useRef(null);

	const toggleAttr = (selector, condition, attribute, value) => {
		condition && selector?.setAttribute(attribute, value);
		!condition && selector?.removeAttribute(attribute, value);
	}

	useEffect(() => {
		const model = document.querySelector(`.${tdvb3DViewerBlock.current.classList[0]} model-viewer`);
		toggleAttr(model, isTouchMove, 'camera-controls', '');
		toggleAttr(model, !isZoom, 'disable-zoom', '');
	}, [tdvb3DViewerBlock.current])

	return <TDViewer attributes={attributes} ref={tdvb3DViewerBlock} />
}